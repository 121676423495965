<template>
    <div class="apply_3d_list" @click="closeList">
        
        <div class="center" @click.stop>
            <div class="title">
                <img src="../assets/img/collaps.png" alt="" @click="closeList">
                <div>选择制作3D展示的珍宝</div>
                <div class="other" @click="changeType">{{activeIndex==1?'藏家珍品':'作者珍品'}} ></div>
            </div>
            <div class="list">
                <div class="items" v-for="(item , index) in show_data" :key="index">
                    <img class="pic" :src="item.pictures[0]">
                    <div class="info">
                        <div class="name">{{item.name}}</div>
                        <div class="nums">长城珍宝编号:{{item.serial_number}}</div>
                    </div>
                    <div class="action" @click="SelectPro(index)" :class="item.is_dimensional != 0?'':item.isSelect?'active':'normal'" >
                        {{returnStatus(item.is_dimensional)}}
                    </div>
                </div>
                <div style="height:1rem"></div>
            </div>
        </div>
        <div class="bottom" @click.stop>
            <div class="left" @click="AllSelect">
                <div class="b_action" :class="allSelect?'active':''"></div>
                全选
            </div>
            <div class="right">
                已选{{selectNum}}件
                <div class="button" @click="comfirm">
                    确认选择
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"apply3dList",
    props:{
        type:{
            type:String,
            default:1
        }
    },
    data(){
        return{
            data_list:[],
            is_next:false,
            show_data:[],
            show_is_next:false,
            col_data_list:[],
            col_is_next:false,
            activeIndex:1,
            allSelect:false,
            selectNum:0,
            numMax:0
        }
    },
    mounted(){
        this.activeIndex = this.type
        this.getData()
    },
   
    methods:{
        async getData(){
            this.selectNum = 0
            await this.axios.get("/goods/getMineGoodsList?artist_type=1", {}).then((res)=>{
                this.data_list = res.data.data.list;
               
            })
            await this.axios.get("/goods/getMineGoodsList?artist_type=2", {}).then((res)=>{
                this.col_data_list = res.data.data.list;
              
            })
            if(this.activeIndex == 1){
                this.show_data = JSON.parse(JSON.stringify(this.data_list))
                this.show_is_next = this.is_next
                let list = this.show_data.filter((item)=>{return item.is_dimensional == 0})
                this.numMax = list.length
            }else{
                this.show_data = JSON.parse(JSON.stringify(this.col_data_list))
                this.show_is_next = this.col_is_next
                let list = this.show_data.filter((item)=>{return item.is_dimensional == 0})
                this.numMax = list.length
            }
        },
        changeType(){
            this.activeIndex = this.activeIndex == 1 ? 2:1
            this.allSelect = false
            if(this.activeIndex == 1){
                this.show_data = JSON.parse(JSON.stringify(this.data_list))
                this.show_is_next = this.is_next
            }else{
                this.show_data = JSON.parse(JSON.stringify(this.col_data_list))
                this.show_is_next = this.col_is_next
            }
            let list = this.show_data.filter((item)=>{return item.is_dimensional == 0})
            this.numMax = list.length
        },
        AllSelect(){
            if(!this.show_data.length){
                return
            }

            this.allSelect = !this.allSelect
            for(let i = 0; i < this.show_data.length;i++){
                if(this.show_data[i].is_dimensional != 0){
                    continue;
                }
                this.show_data[i].isSelect = this.allSelect
            }
            this.getNums()
        },
        getNums(){
            let num = 0
            for(let i= 0; i< this.show_data.length;i++){
                if(this.show_data[i].is_dimensional != 0){
                    continue;
                }
                if(this.show_data[i].isSelect){
                    num++
                }
            }
            this.selectNum = num
            if(num == this.numMax){
                this.allSelect = true
            }else{
                this.allSelect = false
            }
        },
        SelectPro(index){
            this.show_data[index].isSelect = !this.show_data[index].isSelect
            this.getNums()
        },
        comfirm(){
            if(!this.selectNum){
                weui.alert('请选择珍宝')
                return
            }
            let list = []
             for(let i= 0; i< this.show_data.length;i++){
                if(this.show_data[i].isSelect){
                    list.push(this.show_data[i].id)
                }
            }
            this.$emit('comfirm',list,this.activeIndex)
        },
        closeList(){
            this.$emit('closeList')
        },
        returnStatus(e){
            if(e == 0){
                return ''
            }else if(e == 1){
                return '申请中'
            }else if(e == 2){
                return '已完成'
            }else if(e == 3){
                return '未通过'
            }else if(e == 4){
                return '制作中'
            }
        }
    }
}
</script>

<style scoped>
.apply_3d_list{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.top{
    display: flex;
    flex-direction: column;
    background: url(../assets/img/apply3d_top_bg.png) no-repeat;
    background-size: 3.75rem 1.53rem;
    background-position: right bottom;
    flex-shrink: 0;
}
.main{
    padding: 0 0.15rem;
    display: flex;
    justify-content: space-between;
}
.main .left img:nth-child(1){
    margin-top: 0.19rem;
    width: 1.06rem;
    height: 0.2rem;
}
.main .left img:nth-child(2){
    margin-top: 0.14rem;
    width: 1.71rem;
    height: 0.345rem;
}
.main .right img:nth-child(1){
    width: 1.225rem;
    height: 1.19rem;
    position: relative;
    top: -0.21rem;
}
.back{
    margin-top: 0.1rem;
    margin-left: 0.15rem;
    width: 0.72rem;
    height: 0.3rem;
    background: url(../assets/img/back_bg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: #fff;
}
.back img{
    width: 0.07rem;
    height: 0.12rem;
    margin-right: 0.075rem;
}
.center{
    height: calc(100% - 1.53rem);
    background: url(../assets/img/new_form_bg.png) no-repeat;
    background-size: 100% auto;
    box-sizing: border-box;
    padding: 0.15rem;
    display: flex;
    flex-direction: column;
    overflow: scroll;
}
.title{
    display: flex;
    align-items: center;
    font-size: 0.15rem;
    color: #ffebcc;
    flex-shrink: 0;
}
.title img{
    object-fit: contain;
    height: 0.19rem;
    margin-right: 0.12rem;
}
.title .other{
    margin-left: auto;
}
.list{
    flex: 1;
    flex-shrink: 0;
    overflow: scroll;
}
.list .items{
    width: 100%;
    height: 0.76rem;
    background: rgba(255, 255, 255, 0.3);
    margin-top: 0.15rem;
    border-radius: 0.05rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0.15rem;
}
.list .items .pic{
    width: 0.56rem;
    height: 0.56rem;
    object-fit: contain;
    margin-right: 0.1rem;
}
.info{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.info div:nth-child(1){
    font-size: 0.15rem;
    color: #fff;
}
.info div:nth-child(2){
    margin-top: 0.1rem;
    font-size: 0.13rem;
    color: #fff;
}
.action{
    margin-left: auto;
    font-size: 0.11rem;
    color: #fff;
}
.action.normal{
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 0.02rem;
    box-shadow: 0 0 0 1px #fff;
}
.action.active{
    width: 0.2rem;
    height: 0.2rem;
    background: url(../assets/img/apply_gou.png) no-repeat #fff;
    background-size: 0.14rem 0.11rem;
    border-radius: 0.02rem;
    background-position: center center;
}
.bottom{
    width: 100%;
    height: 0.81rem;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #641f28;
    box-shadow: -1px 0 0.05rem #333;
    display: flex;
    z-index: 100;
    box-sizing: border-box;
    padding: 0 0.15rem;
    padding-bottom: 0.1rem;
    align-items: center;
    font-size: 0.13rem;
    color: #fff;
    justify-content: space-between;
}
.bottom .left , .bottom .right{
    display: flex;
    align-items: center;
}
.bottom .left .b_action{
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 0.02rem;
    margin-right: 0.1rem;
    box-shadow: 0 0 0 1px #fff;
}
.bottom .left .b_action.active{
    box-shadow: none;
    width: 0.2rem;
    height: 0.2rem;
    background: url(../assets/img/apply_gou.png) no-repeat #fff;
    background-size: 0.14rem 0.11rem;
    background-position: center center;
}
.bottom .right .button{
    height: 0.5rem;
    width: 1.25rem;
    margin-left: 0.15rem;
    background: linear-gradient(to right,#f2dd9a,#e0ae6d);
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.05rem;
    font-size: 0.15rem;
}
</style>