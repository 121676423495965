<template>
    <div class="apply3d">
        <div class="top">
            <div class="back" @click="goback">
                <img src="../assets/img/back.png" alt="">
                返回
            </div>
            <div class="main">
                <div class="left">
                    <img src="../assets/img/apptomake.png" alt="">
                    <img src="../assets/img/3Dpro.png" alt="">
                </div>
                <div class="right">
                    <img src="../assets/img/3dmodal.png" alt="">
                </div>
            </div>

        </div>
        <div class="apply_goods" >
            <div class="text">
                <img src="../assets/img/gemstone.png" alt="">
                <span>申请作品：</span>
            </div>
            <div class="text" >
                <div class="select" v-show="selectNum">已选{{selectNum}}件</div>
                <div class="button" @click="showList">{{$t('main.chooseTr')}}</div>
            </div>

        </div>
        <div class="form" style="margin-top:0.2rem">
            <img class="titleImg" src="../assets/img/apply_way.png" alt="">
            <p>
                您可以向长城文创珍宝中心提交申请，为您成功发布的珍宝定制3D模型，并在线上展出，可旋转缩放查看局部细节，珍宝全貌一览无余。  
            </p>
            <p>
                提交申请后，若珍宝满足制作3D模型的条件，会有工作人员与您联系，上门扫描珍宝实物。
            </p>
            <img class="titleImg" style="margin-top: 0.22rem" src="../assets/img/apply_data_call.png" alt="">
            <form action="">
                <div class="items">
                    <div class="value">
                        <span>*</span>
                        申请人：
                    </div>
                    <div class="ins">
                        <input type="text" maxlength="10" v-model="name" placeholder="请输入申请人姓名">
                    </div>
                </div>
                <div class="items">
                    <div class="value">
                        <span>*</span>
                        手机号：
                    </div>
                    <div class="ins">
                        <input type="tel" maxlength="13" v-model="phone" placeholder="请输入申请人手机号码">
                    </div>
                </div>
                <div class="items">
                    <div class="value">
                        备注：
                    </div>
                    <div class="ins">
                        <textarea type="text" maxlength="500" v-model="remark" placeholder="请输入3D模型制作相关信息,例如珍宝类型" />
                    </div>
                </div>
                <div style="height:1rem"></div>
            </form>
        </div>
        <div class="bottom">
            <div class="button" @click="submit">
                提交申请
            </div>
        </div>
        <apply3dList v-show="is_show_list" ref="applyList"  :type="$route.query.artistType" @closeList="closeList" @comfirm="getGoodsId"></apply3dList>
    </div>
</template>

<script>
import apply3dList from "../components/apply_3d_list.vue"
export default {
    components:{
        apply3dList
    },
    data(){
        return{
            goods_ids:[],
            selectNum:0,
            artist_type:1,
            name:'',
            phone:'',
            remark:'',
            is_show_list:false
        }
    },
    mounted(){
    },
    methods:{
        getGoodsId(goods_ids,artist_type){
            this.goods_ids = goods_ids
            this.selectNum = goods_ids.length
            this.artist_type = artist_type
            this.is_show_list = false
        },
        closeList(){
            this.is_show_list = false
        },
        showList(){
            this.is_show_list = true
        },
        submit(){
            if(!this.selectNum){
                weui.alert('请选择申请作品')
                return
            }
            if(!this.name){
                weui.alert('请输入姓名')
                return
            }
            if(!this.phone){
                weui.alert('请输入手机号')
                return
            }
            let data = {
                artist_type:this.artist_type,
                name:this.name,
                phone:this.phone,
                remark:this.remark,
                goods_ids:this.goods_ids,
            }
            this.axios.post('/artist/applyDimensional',data).then((res)=>{
                
                if(res.data.code == 0){
                    weui.alert('申请成功')
                    this.$refs.applyList.getData()
                }else{
                    
                    weui.alert(res.data.msg)
                }
            })
        },
        goback(){
            this.$router.back()
        }
    }
}
</script>

<style scoped>
.apply3d{
    background: #450202;
}
.top{
    display: flex;
    flex-direction: column;
    background: url(../assets/img/apply3d_top_bg.png) no-repeat;
    background-size: 3.75rem 1.53rem;
    background-position: right bottom;
}
.main{
    padding: 0 0.15rem;
    display: flex;
    justify-content: space-between;
}
.main .left img:nth-child(1){
    margin-top: 0.19rem;
    width: 1.06rem;
    height: 0.2rem;
}
.main .left img:nth-child(2){
    margin-top: 0.14rem;
    width: 1.71rem;
    height: 0.345rem;
}
.main .right img:nth-child(1){
    width: 1.225rem;
    height: 1.19rem;
    position: relative;
    top: -0.21rem;
}
.back{
    margin-top: 0.1rem;
    margin-left: 0.15rem;
    width: 0.72rem;
    height: 0.3rem;
    background: url(../assets/img/back_bg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: #fff;
}
.back img{
    width: 0.07rem;
    height: 0.12rem;
    margin-right: 0.075rem;
}
.apply_goods {
    width: 3.45rem;
    margin: 0 auto;
    height: 0.7rem;
    background: url(../assets/img/apply_choice.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 0.15rem;
    align-items: center;
}

.apply_goods .text {
    display: flex;
    align-items: center;
    color: #ffebcc;
}

.apply_goods .text img {
    height: 0.19rem;
    margin-right: 0.1rem;
}
.apply_goods .text .select{
    font-size: 0.13rem;
    color: #ffffff;
    margin-right: 10px;
}

.apply_goods .button {
    width: 1rem;
    height: 0.35rem;
    border-radius: 0.17rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #f1dd9b, #e0ae6c);
    font-size: 0.15rem;
    color: #333;
}

.apply_goods .text span {
    margin-right: 0.15rem;
}
.form {
    padding: 0.15rem;
    box-sizing: border-box;
    border-radius: 0.1rem;
    background: url(../assets/img/new_form_bg.png) no-repeat;
    background-size: 100% auto;
}
.form p{
    font-size: 0.12rem;
    color: #fff;
    margin-top: 0.25rem;
}

.form .items{
    display: flex;
    margin-top: 0.2rem;
}
.form .items .value{
    flex-shrink: 0;
    width: 0.8rem;
    font-size: 0.15rem;
    color: #feedcd;
    height: 0.45rem;
    display: flex;
    align-items: center;
}
.form .items .ins{
    flex: 1;
    flex-shrink: 0;
    box-shadow: inset 0 0 0 1px #fff6e0;
    border-radius: 0.05rem;
}

.form .items .ins input {
    flex: 1;
    height: 0.45rem;
    background: transparent;
    outline: none;
    border: none;
    padding: 0.1rem;
    box-sizing: border-box;
    width: auto;
    color: #feedcd;
}

.form .items .ins input::placeholder {
    font-size: 0.13rem;
    color: #feedcd;
}

.form .items .ins input::-moz-placeholder {
    font-size: 0.13rem;
    color: #feedcd;
}

.form .items .ins input::-webkit-input-placeholder {
    font-size: 0.13rem;
    color: #feedcd;
}
.form .items .ins textarea {
    flex: 1;
    height: 100%;
    background: transparent;
    outline: none;
    border: none;
    padding: 0.1rem;
    box-sizing: border-box;
    width: auto;
    color: #feedcd;
}

.form .items .ins textarea::placeholder {
    font-size: 0.13rem;
    color: #feedcd;
}

.form .items .ins textarea::-moz-placeholder {
    font-size: 0.13rem;
    color: #feedcd;
}

.form .items .ins textarea::-webkit-input-placeholder {
    font-size: 0.13rem;
    color: #feedcd;
}

.form .items .put .danw {
    margin-right: 0.15rem;
}
.titleImg{
    object-fit: contain;
    height: 0.3rem;
}
.bottom{
    width: 100%;
    height: 0.81rem;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #641f28;
    box-shadow: -1px 0 0.05rem #333;
}
.bottom .button{
    width: 3.42rem;
    height: 0.51rem;
    margin: 0 auto;
    margin-top: 0.1rem;
    background: linear-gradient(to right,#f2dd9a,#e0ae6d);
    font-size: 0.17rem;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.05rem;
}
</style>